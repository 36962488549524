import React from 'react';
import { CategoriesTags } from '../../../components/common/pages'
import Layout from '../../../components/layout/defaultlayout'

const tags = () => {
  return(
    <>
      <Layout isHome={false}>
        <div id="tag-page" className="container">
          <CategoriesTags/>
        </div>
      </Layout>
    </>
  );
};

export default tags;
